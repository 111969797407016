<template>
  <div class="page">
    <div class="page-content">
      <div class="page-header">
        <div class="page-title">Календарь</div>
        <div
          class="page-header-button default-button"
          @click="$refs.create_event_modal.open({date: currentDate})"
        >
          <div class="icon icon-new"></div>
          <span>Новое событие</span>
        </div>
      </div>
      <div class="calendar-page">
        <div class="calendar-page_main">
          <div class="tabbar">
            <div class="tab-link" :class="{active: tab === 0}" @click="tabChange(0)">
              <span>За день</span>
            </div>
            <div class="tab-link" :class="{active: tab === 1}" @click="tabChange(1)">
              <span>За неделю</span>
            </div>
          </div>
          <v-calendar
            locale="ru"
            ref="calendar"
            :theme="{dayNotInMonth: 'not-in-month'}"
            :attributes="attrs"
            @dayclick="onDayClick"
            @update:from-page="datepickerPageUpdate"
            :masks="{weekdays: 'WW'}"
            :first-day-of-week="2"
            v-model="currentDate"
          >

          </v-calendar>
        </div>
        <EventList :events="events" v-if="events.length">
          <template #itemAfter>
            <div class="icon icon-arrow flip-horizontally"></div>
          </template>
        </EventList>
        <div class="event-list" v-else>
          <div class="event-list_section_head">{{
              $moment(startDateOfPeriod)
                .locale('ru')
                .format('DD MMMM, ddd')
            }}
          </div>
          <div class="no-results-wrap">
            <NoResults>
              <template v-slot:title>Ничего не найдено</template>
              <template v-slot:text>
                Простота создания, быстрота наполнения, фокусировка и результаты
              </template>
            </NoResults>
          </div>
        </div>
      </div>
    </div>
    <CreateEventModal ref="create_event_modal" @created="onEventCreated"/>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import EventList from '@/views/event/EventList.vue';
import CreateEventModal from '@/views/event/create/CreateEventModal';

export default {
  name: 'Calendar',
  components: { CreateEventModal, EventList },
  data() {
    return {
      currentDate: this.$moment().format('YYYY-MM-DD'),
      events: [],
      month: this.$moment()
        .format('YYYY-MM-DD'),
      tab: 0,
      attrs: [
        {
          key: 'current',
          highlight: {},
          dates: new Date(),
        },
        {
          key: 'today',
          dates: new Date(),
          dot: {
            class: 'invisible today-dot',
          },
        },
        {
          key: 'gray-dot',
          dot: {
            color: '(none)',
            class: 'calendar-dot gray',
          },
          dates: [],
        },
        {
          key: 'red-dot',
          dot: {
            color: '(none)',
            class: 'calendar-dot red',
          },
          dates: [],
        },
      ],
      loading: false,
    };
  },
  methods: {
    loadEvents(firstTime = false, from = null, to = null) {
      if (this.tab === 0) {
        if (!from) {
          from = this.currentDate;
        }
        if (!to) to = from;
      } else {
        if (!from) {
          from = this.$moment(this.currentDate)
            .startOf('week')
            .format('YYYY-MM-DD');
        }
        if (!to) {
          to = this.$moment(this.currentDate)
            .endOf('week')
            .format('YYYY-MM-DD');
        }
      }
      this.$server.request2('event/get', {
        type: [0, 1, 2],
        status: [0, 1],
        date_from: from,
        date_to: to,
        order: 'asc',
      }, (data) => {
        this.events = data.response;
      }, {
        onError: () => {
          if (firstTime) {
            setTimeout(() => {
              this.loadEvents(true, from, to);
            }, 5000);
          }
        },
      });
    },
    loadPoints(firstTime = false) {
      this.$server.request2('event/dates', this.fromToCurrentMonth(), (data) => {
        this.getAttrsObjByKey('gray-dot').dates = data.response.filter((day) => day.count < 3)
          .map((day) => new Date(day.date));
        this.getAttrsObjByKey('red-dot').dates = data.response.filter((day) => day.count >= 3)
          .map((day) => new Date(day.date));
        this.updateAttrs();
      }, {
        onError: () => {
          if (firstTime) {
            setTimeout(() => {
              this.loadEvents(true);
            }, 5000);
          }
        },
      });
    },
    fromToCurrentMonth() {
      return {
        from: this.$moment(this.month)
          .startOf('month')
          .locale('ru')
          .format('YYYY-MM-DD'),
        to: this.$moment(this.month)
          .endOf('month')
          .locale('ru')
          .format('YYYY-MM-DD'),
      };
    },
    getAttrsObjByKey(key) {
      return this.attrs.find((obj) => obj.key === key);
    },
    updateAttrs() {
      if (this.tab === 0) {
        this.getAttrsObjByKey('current').dates = this.currentDate;
      } else {
        this.getAttrsObjByKey('current').dates = {
          start: this.$moment(this.currentDate)
            .startOf('week')
            .format('YYYY-MM-DD'),
          end: this.$moment(this.currentDate)
            .endOf('week')
            .format('YYYY-MM-DD'),
        };
      }
      this.attrs = [...this.attrs];
      if (this.$refs.calendar) {
        this.$refs.calendar.$el.querySelectorAll('.first-highlights, .last-highlights')
          .forEach((el) => {
            el.classList.remove('first-highlights');
            el.classList.remove('last-highlights');
          });
        const highlightsEl = this.$refs.calendar.$el.querySelectorAll('.vc-weeks .vc-day > .vc-highlights');
        if (highlightsEl.length) {
          highlightsEl[0].classList.add('first-highlights');
          highlightsEl[highlightsEl.length - 1].classList.add('last-highlights');
        }
        const todayDateEl = this.$refs.calendar.$el.querySelector('.today-date');
        if (todayDateEl) todayDateEl.classList.remove('today-date');
        if (this.$refs.calendar.$el.querySelector('.vc-weeks .vc-day .vc-dots .today-dot')) {
          const newTodayDateEl = this.$refs.calendar.$el.querySelector('.vc-weeks .vc-day .vc-dots .today-dot')
            .closest('.vc-day')
            .querySelector('.vc-day-content');
          if (newTodayDateEl) newTodayDateEl.classList.add('today-date');
        }
      }
    },
    onDayClick(day) {
      this.currentDate = this.$moment(day.date).format('YYYY-MM-DD');
      this.updateAttrs();
      this.loadEvents();
    },
    datepickerPageUpdate(date) {
      const dateMonth = this.$moment(new Date().setFullYear(date.year, date.month - 1, 1))
        .format('YYYY-MM-DD');
      if (this.month !== dateMonth) this.onMonthChanged(dateMonth);
    },
    onMonthChanged(month) {
      this.month = month;
      this.loadPoints();
    },
    tabChange(tab) {
      this.tab = tab;
      this.loadEvents();
      this.updateAttrs();
    },
    onEventCreated(date) {
      this.currentDate = date;
      this.loadEvents(false, date, date);
      this.loadPoints();
    }
  },
  computed: {
    startDateOfPeriod() {
      if (this.getAttrsObjByKey('current').dates.start) {
        return this.$moment(this.getAttrsObjByKey('current').dates.start)
          .format('YYYY-MM-DD');
      }
      return this.$moment(this.getAttrsObjByKey('current').dates)
        .format('YYYY-MM-DD');
    },
  },
  mounted() {
    this.loadEvents(true);
    this.loadPoints(true);
  },
  created() {
    this.$eventBus.on('event-remove, event-update', () => {
      this.loadEvents();
      this.loadPoints();
    });
  },
  beforeDestroy() {
    this.$eventBus.off('event-remove, event-update');
  },
};
</script>

<style lang="scss" scoped>
.calendar-page {
  display: flex;
  justify-content: space-between;

  &_main {
    .tabbar {
      margin-bottom: 28px;
    }

    .tab-link {
      width: 100%;
    }
  }
}

::v-deep(.vc-container) {
  width: 328px;
  background: #F4F3F2;
  border: none;
  border-radius: 10px;

  .vc-header, .vc-arrows-container {
    padding: 18px 28px 0 28px;
  }

  .vc-title {
    color: var(--page-color-main);
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -1px;
    text-transform: capitalize;
  }

  .vc-svg-icon {
    color: var(--page-color-main);
  }

  .vc-weeks {
    padding: 12px 28px;

    & > * {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .vc-weekday {
    margin-bottom: 13px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: rgba(var(--page-color-main-rgb), .5);
    border-bottom: 2px solid var(--border-color);
  }

  .calendar-dot {
    &.gray {
      background-color: rgba(8, 5, 12, .25);
    }

    &.red {
      background-color: #FF4050;
    }
  }

  .vc-highlights {
    margin: 2px 0;
    background: rgba(0, 0, 0, .08);

    &.first-highlights {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &.last-highlights {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .vc-day-content {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;

    &:hover, &:focus {
      background: inherit;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }

    &.today-date {
      color: #009eff;
    }
  }

  .vc-dots {
    position: relative;
    top: 4px;

    .vc-dot.invisible {
      display: none;
    }
  }

  .vc-day.is-not-in-month * {
    opacity: 1;
    color: rgba(var(--page-color-main-rgb), .4)
  }
}

.no-results-wrap {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
</style>
